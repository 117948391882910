.lf-showcase-container.lf-add-offset-margin {
  margin: 300px 0;
  @media (min-width: $breakpoint-xlarge - 1) {
    margin: 340px 0;
  }
  @media (max-width: $breakpoint-large - 1) {
    margin: 320px 0;
  }
  @media (max-width: $breakpoint-medium - 1) {
    margin: 80px 0 40px;
  }
}

.lf-showcase {
  background-color: $c-green;
  color: $c-body-color;
  line-height: 1.5;
  margin-top: 0 !important;
  margin-left: 0 !important;

  &-image {
    background: $global-background;
    height: 640px;
    position: relative;
    @media (max-width: $breakpoint-medium - 1) {
      order: 0 !important;
      margin: 0;
    }
  }

  &-image-left {
    order: 0;
    bottom: 60px;
    margin: -40px 0;
    @media (max-width: $breakpoint-medium - 1) {
      bottom: 0;
    }
  }

  &-image-right {
    order: 1;
    top: 60px;
    margin: -40px 0;
    @media (max-width: $breakpoint-medium - 1) {
      top: 0;
    }
  }

  &-image-default {
  }

  &-content {
    &-adjust-left {
      @media (min-width: $breakpoint-medium) {
      }
    }

    &-adjust-right {
      @media (min-width: $breakpoint-medium) {
      }
    }

    &-adjust-default {
    }
    @media (min-width: $breakpoint-medium) {
      width: calc(50% - 80px);
    }
    @media (max-width: $breakpoint-medium) {
      padding: 20px 40px;
    }

    p:first-child {
      font-family: $f-body;
      // font-size: 20px;
    }

    p {
      font-family: $f-body-thin;
      font-weight: 200;
    }
  }
}

.lf-showcase-content-adjust-default {
  @media (min-width: $breakpoint-medium) {
    width: 50% !important;
  }
}

.lf-showcase:nth-child(even) .lf-showcase-image {
  order: 1;
}
