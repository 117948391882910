.team-member {
  cursor: pointer;
  &:hover {
    .member-tab {
      transform: translateY(-50%);
    }
  }
  .member-tab {
    transition: transform 0.3s ease;
    background: #fff;
    padding: 12px 8px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    h3 {
      margin-bottom: 0px;
    }
    p {
      margin-top: 0;
    }
  }
}

.team-member-modal-content {
  background-color: #2a8f92;
  padding: 0 15px;
  p {
    color: #fff;
  }
}

// .team-member-image {
//     min-height: 350px;;
// }

.team-member-modal-button {
  background-color: transparent;
  font-weight: bold;
  color: #fff;
  &:focus {
    border: none;
    outline: none;
  }
}
