// Custom theme variables.
$global-background: #fff;

// Hero variables.
$hero-max-height: 700px;

// Fonts and global margins.
$f-header: 'Roboto', sans-serif;
$f-body: 'Roboto', sans-serif;
$f-body-thin: 'Roboto', sans-serif;
$f-button-copyright: 'Roboto', sans-serif;
$f-body-weight: 500;
$f-header-letter-spacing: 0.5px;

// Nav variables.
$nav-top-padding: 20px;
$nav-side-padding: 120px;
$nav-height: 80px + $nav-top-padding;

// Random elements.
$global-line-thickness: 3px;
$global-transition-speed: 0.15s;
$transparent: rgba(0,0,0,0);

// Image filter variables.
$image-filter: grayscale(100%) brightness(125%) contrast(125%);
$image-filter-reset: grayscale(0%) brightness(100%) contrast(100%);
$image-overlay-blend-mode: hard-light;
$image-overlay-extra-blend-mode: luminosity;

// Colours.
$c-light-gray: #939393;         // Light gray text
$c-gray: #727272;               // Gray for use in icons
$c-dark-gray: #3b3b3b;          // Body gray
$c-green: #2a8f92;              // Green accent
$c-light-green: #42c8b2;        // Light green accent
$c-body-color: #fff;            // Body white
