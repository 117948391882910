.lf-navbar {
  & &-icon {
    width: 170px;

    & g polygon,
    & path {
      fill: $c-green;
    }
  }

  & &-link {
    font-weight: bold;

    &:hover a {
      color: $c-green !important;
    }

    &-active a {
      color: $c-green !important;
    }

    &:nth-last-of-type(1) {
      font-weight: normal;
      padding-left: 24px;
    }

    &:nth-last-of-type(2) {
      border-right: $global-line-thickness solid $c-green;
      padding-right: 24px;
    }
  }

  &.uk-navbar-container {
    background: $transparent;
    height: $nav-height;
    // padding: $nav-top-padding $nav-side-padding 0;
    padding: 20px 40px 0;
    @media (max-width: $breakpoint-medium) {
      padding: 20px 0 0 15px;
    }
  }

  & > .uk-navbar {
    & > .uk-navbar-left {
      margin: 0 !important;
    }

    & > .uk-navbar-right {
      margin-right: 0;

      & > .uk-navbar-nav > li > a {
        color: $c-dark-gray;
        font-family: $f-body;
        font-size: 16px;
        height: auto;
        margin-top: -4px;
        padding: 0 24px;
        text-transform: uppercase;
      }

      & > .uk-navbar-nav > li > a[uk-navbar-toggle-icon] {
        padding: 24px;
      }
    }
  }
}

.lf-navbar.uk-sticky.uk-active {
  &.uk-navbar-container {
    padding: 0 10px;
    height: auto;
    background-color: $global-background;
    // border-bottom: 1px solid $global-muted-background;
    // box-shadow: 0 0 20px rgba(0,0,0,.05);
  }

  & .lf-navbar-icon {
    width: 150px;
  }

  & > .uk-navbar > .uk-navbar-right > .uk-navbar-nav > li > a {
    padding: 0px 15px;
  }
}

.uk-offcanvas-overlay::before {
  background: rgba(59,59,59,.1);
}

.lf-offcanvas {
  background: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0);
  font-family: "acumin-pro", sans-serif;
  font-size: 22px;
  padding: 40px;

  a {
    color: $c-dark-gray !important;
    margin-left: -30px;
    margin-bottom: 10px;
    font-weight: 500;
    height: auto;
    text-transform: lowercase;
    transition-duration: .2s;

    &:hover {
      text-decoration: none;
      color: #2a8f92 !important;
    }
  }

  .lf-navbar-icon {
    width: 75%;
    margin-left: 25px;
    height: auto;

    g polygon, path {
      fill: #2a8f92;
    }
  }

  .lf-navbar-link {
    list-style: inside;

    &:first-of-type {
      margin-top: 30px;
    }
  }

  .lf-navbar-link a {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  button {
    color: $c-dark-gray !important;
    padding: 25px;
    margin-right: -20px;
    margin-top: -20px;
  }
}
