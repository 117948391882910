.lf-blog-entry-content {
  & a {
    color: $c-green;
    text-decoration: none;
  }
}

.lf-blog-entry-content > section {
  @media (max-width: $breakpoint-small) {
    margin: 40px 0 !important;
  }
}

.lf-blog-listing {
  & li {
    margin-left: 40px;
    padding-left: 0;
  }
}

.pagination {
  & .page-link {
    display: inline-block;
    // width: 32px;
    // height: 32px;
    padding: 12px;
    text-align: center;
    margin: 0 5px;

    & > a {
      color: $c-dark-gray;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      padding: 10px;
      font-size: 21px;
    }

    &.active {
      background: $c-green;

      & > a {
        color: $c-body-color;
        text-decoration: none;
        font-weight: bold;
        padding: 10px;
        font-size: 21px;
      }
    }
  }
}

.pagination.pagination-inverted {
  background-color: $c-green;

  & .page-link {
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;

    & > a {
      color: $c-body-color;
      text-decoration: none;
    }

    &.active {
      background: $c-body-color;

      & > a {
        color: $c-green;
        text-decoration: none;
      }
    }
  }
}

.emphasis {
  border-left: $global-line-thickness solid $c-green;
  font-size: 26px;
  padding-left: 40px;
}

.lf-blog-preview {
  background: $c-green;
  margin-top: 200px;

  &-container {
    margin-top: -280px;
  }

  .lf-blog-preview-link {
    text-decoration: none !important;
    padding: 30px;

    & .lf-blog-preview-links-content {
    }

    & .lf-blog-preview-links-title {
      color: $c-body-color;
      font-family: $f-button-copyright;
      font-size: 20px;
      margin-top: 25px;
    }

    & .lf-blog-preview-links-date {
      color: $c-body-color;
      font-family: $f-body;
      font-size: 14px;
      font-weight: 200;
      text-transform: uppercase;
    }

    & .lf-blog-preview-links-description {
      color: $c-body-color;
      font-family: $f-body;
      font-size: 14px;
      font-weight: 200;
    }

    & .lf-blog-preview-links-featured-image-container {
      &.lf-filter-image {
        filter: grayscale(100%) brightness(125%) contrast(125%);
      }
    }

    & .lf-blog-preview-links-featured-image {
      transition-duration: $global-transition-speed;
      height: 225px;
      margin: 0;
      background-size: cover;
      position: static;
    }

    &:hover .lf-blog-preview-links-featured-image {
      filter: grayscale(0%) brightness(100%) contrast(100%);
    }

    & .lf-filter-overlay {
      opacity: 1;
      transition-duration: $global-transition-speed;
    }

    &:hover .lf-filter-overlay {
      opacity: 0;
    }
  }

  .lf-button {
    margin: 0 auto;
  }
}

.lf-blog-heading .lf-section-header,
.lf-header-link > h2 {
  @media (max-width: $breakpoint-medium) {
    font-size: 28px;
  }
}

.lf-blog-entry-back-link {
  font-size: 34px;
  position: relative;
  top: 3px;
}

.entry-content {
  .rich-text {
    h1,
    h2,
    h3,
    h4,
    h5 {
      display: none;
    }
    img {
      display: none;
    }
  }
}
