.lf-hero {
  // height: calc(100vh - 160px);
  height: calc(50vh);
  min-height: 440px;
  margin-bottom: 250px;

  @media (max-width: $breakpoint-medium) {
    min-height: 520px;
    margin-bottom: 0px;
  }

  &.lf-hero-streamfield {
    height: auto;
  }

  &-image {
    background-position: center;
    filter: $image-filter;
  }

  &-overlay {
    mix-blend-mode: $image-overlay-blend-mode;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  &-overlay-extra {
    mix-blend-mode: $image-overlay-extra-blend-mode;
    background-repeat: no-repeat;
    background-size: cover !important;
    opacity: 0;
  }

  &-main {
    height: 100%;
    @media (max-width: $breakpoint-medium) {
      min-height: 0;
    }

    &-content {
      z-index: 1;
    }

    .lf-hero-header-lead {
      font-family: $f-header;
      font-size: 38px;
      font-weight: bold;
      letter-spacing: $f-header-letter-spacing;
      line-height: 1;
      padding: 0 20px;
      @media (max-width: $breakpoint-medium) {
        line-height: 1.25;
        font-size: 28px;
      }
    }

    .lf-hero-header-emphasis {
      color: $c-green;
      font-family: $f-header;
      font-size: 78px;
      font-weight: bold;
      letter-spacing: $f-header-letter-spacing;
      margin: 20px 0;
      line-height: 1.25;
      @media (max-width: $breakpoint-medium) {
        font-size: 48px;
      }
    }

    .lf-hero-header-content {
      font-size: 22px;
      font-weight: 200;
      @media (max-width: $breakpoint-small-max) {
        font-size: 18px;
      }
    }
  }
}

.lf-homepage-hero {
  // margin-top: -60px;
}
