//
// Component:       Base
//
// ========================================================================

// Here's an example of adding a border to all headings
// @mixin hook-base-heading() {
//   border-bottom: 1px solid $global-color;
// }

@mixin hook-base-heading() {
  font-weight: bold;
}

@mixin hook-base-h2() {
  font-size: 36px;
}
