body {
  color: $c-dark-gray;
  font-family: $f-body;
  font-size: 18px;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;

  @media (max-width: $breakpoint-small-max) {
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $f-header;
    color: $c-green;
  }
}

b {
  font-weight: 900;
}

p, li {
  font-weight: 200;
}

.lf-main {
  margin: auto;
  max-width: 1920px;
  min-height: 100vh;
}

.lf-main-content {
  font-family: $f-body;
  color: $c-dark-gray;
  font-size: 20px;
  font-weight: 500;
}

.lf-section {
  &-secondary {
    padding: 120px 0;
    background: $c-green;
  }
}

.lf-main-content .lf-section-text {
  &-secondary {
    & > * {
      color: $c-body-color;
      margin-bottom: 0 !important;
    }
  }
}

.lf-feature-block .lf-section-text {
  &-primary {
    & a {
      text-transform: none !important;
    }
    & h3 {
      &:hover {
        color: $c-green;
      }
    }
  }
  &-secondary {
    background: white;
    padding: 20px 45px 5px;
  }
  @media (max-width: $breakpoint-medium) {
    padding: 0 0 30px;
  }
}

.lf-section-header {
  // border-left: $global-line-thickness solid $c-green;
  // border-right: $global-line-thickness solid $c-green;
  font-family: $f-header;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: $f-header-letter-spacing;
  line-height: 1;
  margin: auto;
  padding: 0 20px !important;
  width: fit-content;

  &-secondary {
    // border-left: $global-line-thickness solid $c-light-green;
    // border-right: $global-line-thickness solid $c-light-green;
  }
}

.richtext > h1,
.richtext > h2,
.richtext > h3,
.richtext > h4 {
  @extend .lf-section-header;
}

.lf-content-header {
  // border-left: $global-line-thickness solid $c-light-green;
  color: $c-body-color;
  font-weight: bold;
  // padding-left: 20px;
}

.lf-filter {
  &-image {
    filter: grayscale(100%) brightness(100%) contrast(125%);
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-overlay {
    background-repeat: no-repeat;
    background-position-x: 0;
    background-size: cover !important;
    mix-blend-mode: hard-light;

    &-extra {
      background-repeat: no-repeat;
      mix-blend-mode: luminosity;
      opacity: 0.5;
      background-size: cover;
      background-position-x: center;
      background-position-y: top;
    }
  }
}

.lf-press {
  .lf-button {
    margin: 40px auto 0;
    @media (min-width: $breakpoint-large) {
      margin: 70px auto 0;
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #e1e1e1;
    text-align: center;
    text-decoration: none !important;

    & a {
      text-decoration: none;
    }
    @media (max-width: $breakpoint-medium) {
      border-right: none;
    }

    &:nth-child(n+5) {
      border-top: 1px solid #e1e1e1;
    }

    &:nth-child(4n) {
      border-right: none;
    }

    &:nth-child(4n+5) {
      width: 1px;
      -ms-flex: 1;
      -webkit-box-flex: 1;
      flex: 1;
      min-width: 0;
      -ms-flex-preferred-size: 1px;
      flex-basis: 1px;
    }

    &:last-child {
      border-right: none;
    }

    .lf-press-links-logo {
      max-height: 140px;
    }

    .lf-press-links-quote {
      color: #a7a7a7;
      font-size: 14px;
      font-style: italic;
      font-weight: 100;
      margin-top: 20px;
      text-align: center;
      text-decoration: none !important;
    }
  }
}

.lf-bust-out {
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 0 !important;
  width: 80vw;
  max-width: 85%;
  min-height: 640px;
  margin-bottom: 140px !important;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: $breakpoint-medium) {
    width: 100vw;
    max-width: none;
  }

  &-background {
    margin-left: 0 !important;
    width: 80vw;
    min-height: 640px;
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media (max-width: $breakpoint-medium) {
      width: 100vw;
    }
  }

  &-container {
    background: $c-green;
    color: $c-body-color;
    font-weight: 200;
    padding: 50px;
    margin: -280px -10% -80px;
    width: 35%;
    min-width: 350px;
    z-index: 0;
    @media (max-width: $breakpoint-medium) {
      display: block;
      margin: 0 0 -70px;
      width: 100vw;
      min-width: 0;
      padding: 40px;
    }
  }
}

.lf-contact-header {
  text-align: left;
  border-right: none;
  margin: 0;
}

.lf-showcase-content {
  & .rich-text a {
    border-bottom: 1px solid $c-light-green;
  }

  & .rich-text h1,
  & .rich-text h2,
  & .rich-text h3 {
    color: inherit;
  }
}

.rich-text a {
  border-bottom: 1px solid #e1e1e1;
  color: inherit;
  padding-bottom: 2px;
  text-decoration: none;
}

.lf-header-link, .lf-header-link h2 {
  text-decoration: none !important;
  color: $c-dark-gray;
  &:hover {
    color: $c-dark-gray;
  }
}

.lf-section-text {
  & a {
    text-decoration: none !important;
  }
  & a > h3 {
    transition-duration: .15s;

    &:hover {
      color: $c-green !important;
    }
  }
}

.richtext-image {
  &.left {
    float: left;
    padding-right: 30px;
    padding-bottom: 30px;
    margin-left: -30px;
    max-width: 75%;
  }
  &.right {
    float: right;
    padding-left: 30px;
    padding-bottom: 30px;
    margin-right: -30px;
    max-width: 75%;
  }
}

.lf-featured-blog-section {
  @media (min-width: $breakpoint-medium) {
    margin-bottom: 70px;
  }

  & > .uk-container {
    position: relative;
    &::after {
      content: "";

      @media (min-width: $breakpoint-medium) {
        position: absolute;
        height: 3px;
        width: 100%;
        background: #eee;
        margin-top: 70px;
      }
    }
  }
}

.lf-featured-tag {
  background: $c-green;
  color: white;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 5px 10px;
  display: inline-block;
  text-transform: uppercase;
}

.lf-news-tabs {
  border-bottom: 1px solid #C1C1C1;

  @media (min-width: $breakpoint-medium) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.lf-news-tabs-link {
  color: $c-green;
  font-weight: 900;
  font-size: 16px;
  padding: 15px 20px;
  margin-left: 10px;

  &:hover {
    color: $c-green;
    text-decoration: none;
  }

  &.active {
    border-bottom: 5px solid $c-green;
    margin-bottom: -1px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 22px;
    padding: 25px 30px;
    margin-left: 0;
  }
}
