.lf-feature-block {
  & > div > .lf-filter-image {
    filter: $image-filter;
    transition-duration: $global-transition-speed;
  }

  & > div > .lf-filter-overlay,
  & > div > .lf-filter-overlay-extra {
    transition-duration: $global-transition-speed;
  }

  &:hover > div > .lf-filter-image {
    filter: $image-filter-reset;
  }

  &:hover > div > .lf-filter-overlay,
  &:hover > div > .lf-filter-overlay-extra {
    opacity: 0;
  }
}

.lf-feature-icon {
  &-left {
    padding-right: 16px;
  }

  &-center {
    width: 48px;
    height: 48px;
  }
}

.lf-feature-border {
  border-left: $global-line-thickness solid $c-green;
  height: 100%;

  & h3 {
    color: initial !important;
    font-weight: initial !important;
    font-size: 20px !important;
  }

  & .rich-text p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-medium) {
    border-left: none;
  }

  @media (max-width: $breakpoint-large) {
    // padding-left: 30px;
  }
}

.lf-section-text:not(.uk-text-left) {
  & > h3 {
    @media (min-width: $breakpoint-large) {
      margin-top: 30px !important;
    }
    @media (min-width: $breakpoint-medium) {
      margin-top: 0px;
    }
  }
}

.lf-section-text-without-icon {
  @media (min-width: $breakpoint-medium) {
    padding-left: 30px;
  }

  @media (max-width: $breakpoint-medium) {
    padding-bottom: 30px;
    border-bottom: 3px solid $c-green;
  }
}
