.lf-two-column {
  & .uk-cover-container {
    height: 400px;
    margin-bottom: 70px;

    @media (min-width: $breakpoint-medium) {
      height: 680px;
      margin-bottom: 0px;
    }
  }

  & .lf-content-container.lf-align-left > .lf-two-column-content {
    @media (min-width: $breakpoint-medium) {
      padding-right: 80px;
      padding-left: 40px;
    }
  }
}

.lf-two-column-content {
  & p:first-child {
    font-weight: bold;
    font-size: 20px;
  }
}

.lf-two-column-content {
  @media (min-width: $breakpoint-medium) {
    padding-left: 80px;
    padding-right: 40px;
  }
}

.lf-two-column-icons.lf-align-right, .lf-two-column.lf-align-right {
  .lf-two-column-content {
    @media (min-width: $breakpoint-medium) {
      padding-left: 80px;
      padding-right: 40px;
    }
    @media (min-width: $breakpoint-large) {
      padding-left: 80px;
      padding-right: 0px;
    }
  }
}

.lf-two-column-icons.lf-align-left, .lf-two-column.lf-align-left {
  .lf-two-column-content {
    @media (min-width: $breakpoint-medium) {
      padding-left: 40px;
      padding-right: 80px;
    }
    @media (min-width: $breakpoint-large) {
      padding-left: 0px;
      padding-right: 80px;
    }
  }
}

.lf-update-container {
  background: $c-green;
  height: 100%;

  @media (min-width: $breakpoint-medium) {
    padding: 40px 0;
    width: calc(40% - 80px);
  }
}

.lf-update-image-container {
  height: 400px;

  @media (min-width: $breakpoint-medium) {
    position: relative;
    left: 40px;
  }
}

.lf-update-image-container {
  .lf-filtered-image {
    filter: grayscale(100%) brightness(100%) contrast(125%);
    transition-duration: .25s;
    transition-property: filter;
  }

  &:hover {
    .lf-filtered-image {
      filter: grayscale(0%) brightness(100%) contrast(100%);
    }

    .lf-filter-overlay, .lf-filter-overlay-extra {
      opacity: 0;
    }
  }

  .lf-filter-overlay {
    opacity: .7;
    transition-duration: .25s;
  }
}

.lf-two-column-icons {
  @media (max-width: $breakpoint-small-max) {
    padding-bottom: 0px;
  }
}

.lf-icons-background {
  @media (max-width: $breakpoint-small-max) {
    height: 400px;
    position: static;
    margin-bottom: 30px;
  }
}

.lf-icons {
  width: 280px;
  // transform: translateY(-50px);

  &.lf-icons-right {
    width: auto;
    max-width: 480px;
  }
}

.lf-benefits {
  color: $c-dark-gray;
  background-color: #2a8f92;

  & .uk-grid-medium {
    @media (max-width: $breakpoint-small-max) {
      padding: 25px;
    }
  }

  & .lf-section-header {
    color: $c-body-color;
  }

  & p:last-child {
    margin-bottom: 0;
  }
}
