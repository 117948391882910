.lf-button {
  border: $global-line-thickness solid;
  padding: 0;
  font-family: $f-button-copyright;
  font-size: 17px;
  font-weight: bold;
  margin-top: 10px;
  transition-duration: $global-transition-speed;

  &-content {
    display: flex;
    align-items: center;
  }

  &-text {
    border-right: $global-line-thickness solid;
    line-height: 1;
    padding: 15px 20px 14px;
  }

  &-icon {
    align-items: center;
    border-color: inherit;
    display: flex;
    left: 0;
    padding: 0 20px;
    position: relative;
    transition-duration: $global-transition-speed;

    & #path0 {
      fill: $c-body-color;
    }

    & #path1 {
      fill: $c-body-color;
    }
  }

  &.uk-button-primary {
    background-color: $c-body-color;
    border-color: $c-green;
    color: $c-dark-gray;

    .lf-button-text {
      background-color: $global-background;
      border-color: $c-green;
    }

    .lf-button-icon {
      & #path0 {
        fill: $c-green;
      }

      & #path1 {
        fill: $c-green;
      }
    }

    &:hover {
      background-color: $c-green;

      .lf-button-icon {
        & #path0 {
          fill: $c-body-color;
        }

        & #path1 {
          fill: $c-body-color;
        }
      }
    }
  }

  &.uk-button-secondary {
    background-color: $c-green;
    border-color: $c-light-green;
    color: $c-body-color;

    &:hover {
      background-color: $c-light-green;
    }

    .lf-button-text {
      background-color: $c-green;
      border-color: $c-light-green;
    }
  }

  &.uk-button-default {
    @extend .uk-button-secondary
  }

  &:hover {
    .lf-button-icon {
      left: 3px;
    }
  }
}
