.lf-footer {
  &-sections {
    margin-left: 0;
  }

  &-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #e1e1e1;

    &-heading {
      color: $c-green;
      font-family: $f-body-thin;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-content {
      color: $c-gray;
      font-family: $f-body;
      font-weight: 200;
      text-align: center;

      a:not(.uk-icon) {
        border-bottom: 1px solid #e1e1e1;
        color: $c-gray;
        padding-bottom: 2px;
        text-decoration: none;
        transition-duration: $global-transition-speed;

        &:hover {
          border-bottom: 1px solid $c-light-green;
          color: $c-dark-gray;
        }
      }

      .lf-footer-icon-list li {
        background: $c-light-gray;
        border-radius: 100%;
        width: 24px;
        height: 34px;
        display: flex;
        margin-right: 20px;
        margin-left: 0 !important;
        transition-duration: $global-transition-speed;

        &:hover {
          background: $c-green;
        }

        &:last-child {
          margin-right: -10px;
        }

        & a {
          color: $c-body-color;
          position: relative;
          top: 0;
          left: -12px;
          padding: 7px 10px 7px 10px;
        }
      }

      .lf-footer-icon {
        border-bottom: none;
      }
    }
    @media (max-width: $breakpoint-medium) {
      border-right: none;
    }
    @media (max-width: $breakpoint-medium) {
      border-right: none;
    }
    @media (min-width: $breakpoint-medium) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &:first-child {
    }

    &:last-child {
      border-right: none;
    }
  }
  @media (max-width: $breakpoint-medium) {
    margin-top: inherit !important;
    margin-bottom: 0px;
  }
}

.lf-copyright {
  border-top: 1px solid #e1e1e1;
  padding: 40px 30px;
  color: $c-gray;
  font-family: $f-button-copyright;
  text-align: center;
  font-weight: 200;
}
