$w: 100px;

@keyframes slideLeft {
  0% {
		transform: translate3d(-200%,0,0);
	}
  100% {
		transform: translateZ(0);
	}
}

@keyframes slideRight {
  0% {
		transform: translate3d(200%,0,0);
	}
  100%  {
    transform: translateZ(0);
  }
}

.homepage-animation {
	position: relative;
	bottom: 2885px;
	opacity: 0.65;
	z-index: -1;
	overflow: hidden;
	width: 100%;
}

.homepage-animation div {
	position: relative;
	left: -200px;
	top: 0;
	white-space: nowrap;
	user-select: none;
	animation-duration: 3s;
  animation-timing-function: cubic-bezier(.44,1,1,1);
  backface-visibility: visible;
  transform-style: preserve-3d;

	&.odd {
		animation-name: slideRight !important;
    right: $w - ($w / 2);
	}

	&.even {
		position: relative;
		left: 63px;
		animation-name: slideLeft !important;
    right: $w;
	}

	& .vial {
		position: relative;
		display: inline-block;
		width: $w;
		height: $w * 2;
		background-image: url("../images/whitecap.png");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	& .vial.red {
		background-image: url("../images/orangecap.png");
	}
}

.homepage-animation-slow div {
	animation-duration: 6s;
}

.homepage-animation-slowest div {
	animation-duration: 12s;
}
